import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import { startGetMyStats } from "../action/myStats";


import Loader from "../Loader/Loader";

import {
  apexMystatPie,
  apexBar2,
  apexMystatLine,
  apexMultiline,
  apexSideBar,
} from "../../ApexOptions";

import "./MyStats.css";

function MyStats(props) {

  console.log(Object.keys(props.myStats).length, "content length")
  return (
    <div className="center">
    { Object.keys(props.myStats).length > 0 ?
    (<div className="mystats">
      <div className="mystat_row1">
        <div
          className="mystat_cards"
          style={{
            backgroundImage: `url(${
              window.location.origin + "/images/svg/tatal_leads.svg"
            })`,
          }}
        >
          <h3 className="mystat_h3">Total Leads</h3>
          <h2 className="mystat_h2">{props.myStats.total_leads}</h2>
          <p className="mystat_p1">+{props.myStats.total_leads_today}</p>
        </div>
        
        <div
          className="mystat_cards"
          style={{
            backgroundImage: `url(${
              window.location.origin + "/images/svg/QC_PASSED.svg"
            })`,
          }}
        >
          <h3 className="mystat_h3">QC Approved</h3>
          <h2 className="mystat_h2" style={{ color: "#118026" }}>
            {props.myStats.total_qc_passed}
          </h2>
          <p className="mystat_p1" style={{ background: "#CAEFE5" }}>
            +{props.myStats.total_qc_passed_today}
          </p>
        </div>
        <div
          className="mystat_cards"
          style={{
            backgroundImage: `url(${
              window.location.origin + "/images/svg/REJECTED.svg"
            })`,
          }}
        >
          <h3 className="mystat_h3">QC Rejected</h3>
          <h2 className="mystat_h2" style={{ color: "#F75D5D" }}>
            {props.myStats.total_qc_rejected}
          </h2>
          <p className="mystat_p1" style={{ background: "#fedbdb" }}>
            +{props.myStats.total_qc_rejected_today}
          </p>
        </div>
        <div
          className="mystat_cards"
          style={{
            backgroundImage: `url(${
              window.location.origin + "/images/svg/TOTAL_checkin1.svg"
            })`,
          }}
        >
          <h3 className="mystat_h3">Total Checkin</h3>
          <h2 className="mystat_h2" style={{ color: "#FFB100",    "lineHeight": "46px" }}>
            {props.myStats.total_checkin}
          </h2>
          <div className="small_checkin_box">
            <div className="small_xhexkin_xol1">
              <p className="small_checkin_p1">
                FD
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_fd}
              </p>
            </div>
            <div className="small_xhexkin_xol1">
            <p className="small_checkin_p1">
                HD
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_hd}
              </p>
            </div>
            <div className="small_xhexkin_xol1">
            <p className="small_checkin_p1">
                Star
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_star}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mystat_row3">
        <div className="mystat_card">
          <div className="mystat_card_row1">
            <div className="mystat_card1">
              <p className="mystat_p2">ASM-wise checkin</p>
              <Chart
                options={apexMystatPie.options}
                series={apexMystatPie.series}
                type="pie"
                width="350"
              />
            </div>
            <div className="mystat_card1">
              <p className="mystat_p2">Lead registration</p>
              <Chart
                options={apexBar2.options}
                series={apexBar2.series}
                type="bar"
                height={250}
                // width={400}
              />
            </div>
            <div className="mystat_card1" style={{ borderRight: "none" }}>
              <p className="mystat_p2">Total Attendence</p>
              <Chart
                options={apexMystatLine.options}
                series={apexMystatLine.series}
                type="area"
                height={250}
              />
            </div>
          </div>
          <div className="mystat_card_row1">
            <div
              className="mystat_card1"
              style={{ borderTop: "1px solid #e8e8e8",borderRight: "none" }}
            >
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Daywise overview
              </p>
              <Chart
                options={apexMultiline.options}
                series={apexMultiline.series}
                type="line"
                height={250}
              />
            </div>
            {/* <div className="mystat_card2">
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Stagewise Overview
              </p>
              <Chart
                options={apexSideBar.options}
                series={apexSideBar.series}
                type="bar"
                height={250}
              />
            </div> */}
          </div>
        </div>
      </div>
      
    </div>):
      (
        <div className="loader"><Loader /></div>
      )
    }
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    myStats: state.myStats,
  };
};

export default connect(mapStateToProps)(MyStats);
