  
const loggedinInitialState=false

const loggedinReducer=(state=loggedinInitialState,action)=>{
    switch(action.type){
      case "GET_LOGIN":{
          return true
      }
      case "GET_LOGOUT": {
        return false
      }
      default:{
          return false
      }
  }
}

export default loggedinReducer