
const getLogg=(login)=>{
    return {type:"GET_LOGIN"}
  }
  
  const getLogout=(login)=>{
    return {type: "GET_LOGOUT"}
  }
  
  const startLogin=(data)=>{
    return (dispatch)=>{
      console.log(data,"login inside action")
            const response=data
            if(response){
            dispatch(getLogg(response))
            }else {
            dispatch(getLogout(response))
  
            }
    }
  }
  
  export {
    startLogin,
    getLogg,
    getLogout
  };