import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Dash.css";
import { connect } from "react-redux";

import { startLogin } from "./action/loggedin";
import { startGetProjectList } from "./action/projectList";
import { startGetMyStats } from "./action/myStats";
import Table from "./STable/Table";
import AsmList from "./AsmList/AsmList";
import { Route } from "react-router-dom";
import TlList from "./TlList/TlList";
import FseList from "./FseList/FseList";
import { mainStats, MAIN_STATS_URL } from "../utils";
import Swal from "sweetalert2";
import AddCm from "./AddCm/AddCm";
import AsmProfile from "./AsmProfile/AsmProfile";
import CmProfile from "./CmProfile/CmProfile";
import AddAsm from "./AddAsm/AddAsm";
import { LinearProgress } from "@material-ui/core";
import {CmContext} from "./../App";
function Dash(props) {
  const [context,setContext]=useContext(CmContext);
  const [loading, setLoading] = useState(false);
  const [mainStats, setMainStats] = useState({});
  const [cmId, setCmId] = useState(-1);
  const [cmStats, setCmStats] = useState({});
  const history = useHistory();
  const logoutButton = () => {
    localStorage.removeItem("hr_token");
    localStorage.removeItem("hr_email");
    props.dispatch(startLogin(false));
  };

  // const handleView = (e) => {
  //   props.dispatch(startGetMyStats(e));
  //   // console.log(id,"button view")
  //   // console.log(e,"button view")
  // };
  // useEffect(() => {
  //   props.dispatch(startGetProjectList("4"));
  // }, []);

  useEffect(() => {
    const asyncFunc = async () => {
      const res1 = await fetch(MAIN_STATS_URL, {
        method: "POST",
      });
      const res = await res1.json();
      console.log("main stats response HR", res);
      if (res.error) {
        Swal.fire({
          icon: "error",
          text: "Unable to fetch main stats",
        });
      } else {
        setMainStats({ ...res });
      }
    };
    asyncFunc();
  }, []);

  

  console.log(props.projectList, "proj_list");
  return (
    <div className="dashboard">
      <div className="dashboard_col2">
        <div className="makeFixed">
          <div className="header-material">
            
            <div className="dash_header_col2">
              <p className="dash_header_p1">Welcome back,</p>
              <h1 className="dash_head_name">
                {JSON.parse(localStorage.getItem("profileObj")).givenName}
              </h1>
            </div>
            <div className="dash_header_col1">
              <Link to="/login">
                <div className="logout_box" onClick={() => logoutButton()}>
                  <img
                    src={
                      window.location.origin + "/images/logo/logout_black.svg"
                    }
                    alt="ott"
                    className="logout_ott"
                  />
                  Logout
                </div>
              </Link>
            </div>
          </div>
          <div>
            {loading?<LinearProgress/>:null}
          </div>

          {/* <img
            src={window.location.origin + "/images/profile image.svg"}
            alt="profile"
            className="dash_profile"
          /> */}
        </div>

        <div style={{ marginTop: "52px" }}></div>

        <Route exact path="/dash">

        </Route>
        <Route path="/dash/asmList/">
          <AsmList
            cmId={cmId}
            cmStats={cmStats}
            loading={loading}
            setLoading={setLoading}
          />
        </Route>
        <Route path="/dash/fseList">
          <FseList loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/dash/addCm">
          <AddCm loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/dash/addAsm/:cmIdForAddAsm">
          <AddAsm loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/dash/asmProfile">
          <AsmProfile loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/dash/cmProfile/:cmIdForVp">
          <CmProfile loading={loading} setLoading={setLoading} />
        </Route>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projectList,
  };
};

export default connect(mapStateToProps)(Dash);
