  
const loginErrorFSInitialState="";

const loginErrorFSReducer=(state=loginErrorFSInitialState,action)=>{
    switch(action.type){
      case "HAVE_ACCESS":{
          return ""
      }
      case "NO_ACCESS": {
        return "You don't have access please contact admin."
      }
      default:{
          return ""
      }
  }
}

export default loginErrorFSReducer