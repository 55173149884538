const checkEmail = "https://nodeapi.taskmo.com/v1/hr/cm/check_email";
const MAIN_STATS_URL = "https://isp.taskmo.in/hr/stats";
const CM_LIST_URL = "https://isp.taskmo.in/hr/cm_list";
const RM_URL = "https://nodeapi.taskmo.com/v1/hr/rm";
const ASM_LIST_URL = "https://isp.taskmo.in/hr/asm_list";
const FSE_LIST_URL = "https://nodeapi.taskmo.com/v1/hr/fse/list";
// const FSE_LIST_URL = "https://isp.taskmo.in/hr/fse_list";
const ASM_STATS_URL = "https://isp.taskmo.in/hr/asm_stats";
const CM_STATS_URL = "https://isp.taskmo.in/hr/cm_stats";
const CM_URL = "https://nodeapi.taskmo.com/v1/hr/cm";
const BASE_URL = "https://cm.taskmo.com";
const CM_WISE_TODAY_CHECKINS =
  "https://nodeapi.taskmo.com/v1/hr/cm/checkin/today";
const CM_WISE_THIS_MONTH_CHECKINS =
  "https://nodeapi.taskmo.com/v1/hr/cm/checkin/this_month";
const CM_WISE_TODAY_LEADS = "https://nodeapi.taskmo.com/v1/hr/cm/leads/today";
const CM_WISE_THIS_MONTH_LEADS =
  "https://nodeapi.taskmo.com/v1/hr/cm/leads/this_month";
const FSE_CHART_URL = "https://nodeapi.taskmo.com/v1/hr/fse/chart";
const FSE_WISE_CHECKINS = "https://nodeapi.taskmo.com/v1/hr/fse/checkins";
const CM_stats_new = "https://farming-backend.taskmo.in/cm/stats";
const checkInvalid = (value) => {
  return value == null;
};
const getXleads = (value) => {
  if (value < 3) {
    return value;
  } else if (value >= 3 && value < 5) {
    return value - 3;
  } else if (value >= 5) {
    return value - 5;
  }
  return value;
};
function formatAMPM(hhmmss) {
  let [hours, minutes] = hhmmss.split(":");
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
export {
  BASE_URL,
  checkEmail,
  MAIN_STATS_URL,
  CM_LIST_URL,
  RM_URL,
  ASM_LIST_URL,
  FSE_LIST_URL,
  ASM_STATS_URL,
  CM_STATS_URL,
  CM_URL,
  CM_WISE_TODAY_CHECKINS,
  CM_WISE_THIS_MONTH_CHECKINS,
  CM_WISE_TODAY_LEADS,
  CM_WISE_THIS_MONTH_LEADS,
  FSE_CHART_URL,
  FSE_WISE_CHECKINS,
  CM_stats_new,
  checkInvalid,
  getXleads,
  formatAMPM,
};
