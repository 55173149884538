  
const accessPinInitialState=""

const accessPinReducer=(state=accessPinInitialState,action)=>{
    switch(action.type){
      case "GET_ACCESS_PIN":{
          return action.payload.slice()
      }
      default:{
          return state.slice()
      }
  }
}

export default accessPinReducer