export const statesInitializer={
    name:"mayur",
    activeFseId:0,
    fseListApi:{},
    fseDetailsPopup:{
        full_name:"mayur",
        location:"bangalore",
        profile_image:"",
        leads_thismonth:-1,
        leads_today:-1,
        approved_thismonth:-1,
        approved_today:-1
    },
    daysAttendance:[
        { date: "2021-05-01", status: "" },
        { date: "2021-05-02", status: "" },
        { date: "2021-05-03", status: "" },
        { date: "2021-05-04", status: "" },
        { date: "2021-05-05", status: "" },
        { date: "2021-05-06", status: "" },
        { date: "2021-05-07", status: "" },
        { date: "2021-05-08", status: "" },
        { date: "2021-05-09", status: "" },
        { date: "2021-05-10", status: "" },
        { date: "2021-05-11", status: "" },
        { date: "2021-05-12", status: "" },
        { date: "2021-05-13", status: "" },
        { date: "2021-05-14", status: "" },
        { date: "2021-05-15", status: "" },
        { date: "2021-05-16", status: "" },
        { date: "2021-05-17", status: "" },
        { date: "2021-05-18", status: "" },
        { date: "2021-05-19", status: "" },
        { date: "2021-05-20", status: "" },
        { date: "2021-05-21", status: "" },
        { date: "2021-05-22", status: "" },
        { date: "2021-05-23", status: "" },
        { date: "2021-05-24", status: "" },
        { date: "2021-05-25", status: "" },
        { date: "2021-05-26", status: "" },
        { date: "2021-05-27", status: "" },
        { date: "2021-05-28", status: "" },
        { date: "2021-05-29", status: "" },
        { date: "2021-05-30", status: "" },
        { date: "2021-05-31", status: "" }
      ],
    fseChartApi:[],
    fseWiseCurMonthCheckinsApi:[]
}