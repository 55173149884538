
const haveAccess=()=>{
  return {type:"HAVE_ACCESS"}
}

const noAccess=()=>{
  return {type: "NO_ACCESS"}
}

const startAccess=(data)=>{
  return (dispatch)=>{
    console.log(data,"login access inside action")
          const response=data;
          if(response){
          dispatch(haveAccess(response))
          }else {
          dispatch(noAccess(response))

          }
  }
}

export {
  startAccess,
  haveAccess,
  noAccess
}; 