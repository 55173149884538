import React, { useEffect, useState } from "react";
import "./AsmList.css";

import { Link, useHistory, useParams } from "react-router-dom";

import Chart from "react-apexcharts";
import { Modal } from "@material-ui/core";

import Calender2 from "../calender2/Calender2";
// import CalenderAttend from "../calender/Calender";
import { apexMultiline, apexBarAsm } from "../../ApexOptions";
import {
  ASM_LIST_URL,
  ASM_STATS_URL,
  checkInvalid,
  CM_STATS_URL,
  CM_stats_new,
} from "../../utils";
import Swal from "sweetalert2";
import axios from "axios";
// import asmDummyData from "./asmDummyData";
//remove the above import if local api test

function AsmList({ cmId, cmStats, setLoading }) {
  localStorage.removeItem("asm_id");
  const { cmIdFromParam, cmName, cmCity } = useParams();
  // console.log(cmIdFromParam,"cmIdfromparam")
  const [asmDummyData, setAsmDummyData] = useState([]);
  const [myCmStats, setMyCmStats] = useState({});
  const [active, setActive] = useState([]);
  const [open, setOpen] = useState(false);
  const [new_stats, setNewstas] = useState(false);
  const history = useHistory();
  const handleClick = (index) => {
    active[index] = !active[index];
    setActive([...active]);
  };

  const handlePop = () => {
    setOpen(true);
    setActive(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const number1 = [];

  for (var i = 1; i <= 31; i++) {
    number1.push(i);
  }

  useEffect(() => {
    const postData = {
      cm_id: localStorage.getItem("hr_admin_id"),
    };
    axios
      .post(CM_stats_new, postData)
      .then((res) => {
        setNewstas(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
    const asyncFunc = async () => {
      const lAsmStats = localStorage.getItem("asmStats");
      const pAsmStats = JSON.parse(lAsmStats);
      setMyCmStats({ ...pAsmStats });
      setLoading(true);
      const res1 = await fetch(CM_STATS_URL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cm_id: localStorage.getItem("hr_admin_id"),
        }),
      });
      if (res1.ok) {
        const res = await res1.json();
        if (res.error) {
          setLoading(false);
        } else {
          localStorage.setItem("asmStats", JSON.stringify({ ...res }));
          setMyCmStats({ ...res });
          setLoading(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
  }, []);

  useEffect(() => {
    const asyncFunc = async () => {
      const myData = localStorage.getItem("lasmDummyData");
      if (!checkInvalid(myData)) {
        const pMydata = JSON.parse(myData);
        setAsmDummyData([...pMydata.data]);
        pMydata.data.forEach(() => {
          active.push(false);
        });
      }
      setLoading(true);
      const res1 = await fetch(ASM_LIST_URL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cm_id: localStorage.getItem("hr_admin_id"),
        }),
      });
      if (res1.ok) {
        const res = await res1.json();
        if (res.error) {
          setLoading(false);
        } else {
          // console.log(res,"ASM LIST");
          const arrangeArr = res.asm_list.map((obj) => {
            return {
              asm_id: obj.asm_id,
              is_active: obj.onboard_status,
              tl_name: obj.full_name,
              team_size: obj.total_fse,
              doj: obj.doj,
              location: obj.city,
              checkin_thismonth: obj.total_checkin,
              checkin_today: obj.total_checkin_today,
              leads_thismonth: obj.total_leads,
              leads_today: obj.total_leads_today,
              approved_thismonth: obj.total_approved,
              approved_today: obj.total_approved_today,
              onboarded_by: obj.onboarded_by,
              onboarded_on: obj.onboarded_on.substr(0, 10),
              inactivated_by: "",
              profile_image: obj.profile_image,
              mobile_number: obj.mobile_number,
            };
          });
          setLoading(false);
          setAsmDummyData([...arrangeArr]);
          localStorage.setItem(
            "lasmDummyData",
            JSON.stringify({
              data: [...arrangeArr],
            })
          );
          arrangeArr.forEach(() => {
            active.push(false);
          });
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
  }, []);
  return (
    <div className="tl_list">
      <div className="dashtl_header">
        <div className="tl_list_row1">
          {/* <Link to="/dash">
            <img
              src={window.location.origin + "/images/svg/back_button.svg"}
              alt="profile"
              className="back_butt"
            />
          </Link> */}
          {/* <div className="track_tl">
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training_assigned.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1">Training Assigned</p>
            </div>

            <div className="dash_tl_line"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Training Completed
              </p>
            </div>

            <div className="dash_tl_line1"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Payment
              </p>
            </div>
          </div> */}
        </div>

        <div className="tl_list_row1">
          {/* <div className="dashtl_h_col1">
            <img
              src={window.location.origin + "/images/png/profile1.png"}
              alt="profile"
              className="profile_tl"
            />
            <h3 className="tl_list_h3">anjana</h3>
            <p className="dashtl_h_p5">TL</p>
          </div> */}
          <div className="dashtl_h_col2">
            <img
              src={window.location.origin + "/images/profile_default_new.svg"}
              // src={`${
              //   Object.keys(myCmStats).length > 2
              //     ? myCmStats.profile_image
              //     : "XXXX"
              // }`}
              alt="profile"
              className="profile_tl"
              onError={(e) => {
                e.target.src =
                  window.location.origin + "/images/profile_default_new.svg";
              }}
            />
            <div className="dashtl_h_row1"></div>
            <div className="dashtl_h_row2">
              <p className="dashtl_h_p1">
                {Object.keys(myCmStats).length > 2 ? myCmStats.cm_name : "XXXX"}
              </p>
              <p className="dashtl_h_p5">City Manager</p>
              <p className="dashtl_h_p2">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.cm_location
                  : "XXXX"}
              </p>
            </div>
          </div>
          <div className="dashtl_h_col3">
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Checkin</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_checkin
                  : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_checkin_today
                  : "XXXX"}{" "}
                Today
              </div>
            </div>
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Leads</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2 ? new_stats.total : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? new_stats.total_total
                  : "XXXX"}{" "}
                Today
              </div>
            </div>

            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Approved</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? new_stats.approved
                  : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? new_stats.total_approved
                  : "XXXX"}{" "}
                Today
              </div>
            </div>
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Active</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_asm_count
                  : "XX"}
                :
                {Object.keys(myCmStats).length > 2 ? myCmStats.total_fse : "XX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>ASM:FSE</div>
            </div>
          </div>
          {/* <div className="dashtl_h_col4">
            <img
              src={window.location.origin + "/images/svg/message.svg"}
              alt="profile"
              className="icon_svg"
            />
            <img
              src={window.location.origin + "/images/svg/call.svg"}
              alt="profile"
              className="icon_svg"
            />
          </div> */}
        </div>
      </div>

      <div className="asmActionBtns">
        {/* <button>Download Report</button> */}
        {/* <div
          className="button_add"
          style={{ width: "95px", marginRight: "15px" }}
          onClick={() => {
            history.push(`/dash/addAsm/${cmIdFromParam}`);
          }}
        >
          <img
            src={window.location.origin + "/images/svg1/add.svg"}
            alt="ott"
            className="add_cm_image"
          />
          Add ASM
        </div> */}
      </div>
      {/* <div className="container2">
        <div className="bar_container">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Overview
          </p>
          <Chart
            options={apexBarAsm.options}
            series={apexBarAsm.series}
            type="bar"
            height={350}
            width={2200}
          />
        </div>
        <div className="calender_container">

          <Calender2 />
          <div style={{ margin: "20px" }}>Performance View</div>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={330}
            width={370}
          />
        </div>
      </div>
       */}
      <div className="tl_list_row2">
        <div className="asmListTitle">List of TSM</div>
      </div>
      {/* <div className="tl_list_row2">
        <div className="myTeam_card">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Daywise overview
          </p>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={250}
          />
        </div>
      </div> */}

      <div className="dashtl1_row1">
        {/* {1 === 0 ? (
          <div className="dash_row4">
            <></>
          </div>
        ) : ( */}
        {/* {props.fseList.map((fse_data) => (         key={fse_data.user_id}*/}
        {asmDummyData.map(
          (
            {
              is_active,
              tl_name,
              team_size,
              doj,
              location,
              checkin_thismonth,
              checkin_today,
              leads_thismonth,
              leads_today,
              approved_thismonth,
              approved_today,
              onboarded_by,
              onboarded_on,
              inactivated_by,
              profile_image,
              mobile_number,
            },
            index
          ) => (
            <div className="dashtl_card">
              <div className="dashtl_card_row1">
                <div className="dashtl1_card_col1">
                  <div className="dashtl_small_col">
                    <img
                      src={`${profile_image}`}
                      alt="profile"
                      className="profile_tl"
                      onError={(e) => {
                        e.target.src =
                          window.location.origin +
                          "/images/profile_default_new.svg";
                      }}
                    />
                    {is_active === "onboarded" ? (
                      <p className="tllist_active">Onboarded</p>
                    ) : null}
                    {is_active === "none" ? (
                      <>
                        <p
                          className="tllist_active"
                          style={{ color: "red", background: "#ffd9d9" }}
                        >
                          None
                        </p>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                    {is_active === "hold" ? (
                      <>
                        <p
                          className="tllist_active"
                          style={{ color: "red", background: "#ffd9d9" }}
                        >
                          Hold
                        </p>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                    {is_active === "inactive" ? (
                      <>
                        <p
                          className="tllist_active"
                          style={{ color: "red", background: "#ffd9d9" }}
                        >
                          Terminated
                        </p>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                  </div>

                  <div className="dashtl1_card_col2 ">
                    <div
                      className="dashtl_card_row2"
                      style={{ margin: "10px" }}
                    >
                      {/* {1 !== "NA" ? (
                            <img
                              src={window.location.origin + "/images/svg/active.svg"}
                              alt="profile"
                              className="dash_active"
                            />
                          ) : (
                            <img
                              src={window.location.origin + "/images/svg/inactive.svg"}
                              alt="profile"
                              className="dash_active"
                            />
                          )} */}
                      <p className="dashtl_p6">{`${tl_name}`}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">
                        <img
                          src={
                            window.location.origin + "/images/mobile_num.svg"
                          }
                          alt="number"
                        />
                      </p>
                      <p className="dashtl_card_p2">{mobile_number}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">
                        <img
                          src={window.location.origin + "/images/team_size.svg"}
                          alt="team_size"
                        />
                      </p>
                      <p className="dashtl_card_p2">{team_size}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">
                        <img
                          src={window.location.origin + "/images/calendar.svg"}
                          alt="calendar"
                        />
                      </p>
                      <p className="dashtl_card_p2">{`${doj.substring(
                        8,
                        10
                      )}/${doj.substring(5, 7)}/${doj.substring(0, 4)}`}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      <p className="dashtl_card_p1">
                        <img
                          src={window.location.origin + "/images/location.svg"}
                          alt="location"
                        />
                      </p>
                      <p
                        className="dashtl_card_p2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {location}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="dashtl_card_col3"
                  onClick={() => handleClick(index)}
                >
                  <img
                    src={
                      window.location.origin + "/images/svg/view_details.svg"
                    }
                    alt="profile"
                    className="svg_myteam"
                  />

                  {active[index] ? (
                    <div className="dashtl_dropdown">
                      <div className="tl_dropdown">
                        <p
                          className="myteam_drop_p1"
                          // onClick={handlePop}
                          onClick={() => {
                            localStorage.setItem(
                              "asm_id",
                              asmDummyData[index].asm_id
                            );
                            setTimeout(() => {
                              console.log("time pass");
                            }, 100);
                            window.scrollTo(0, 0);
                            history.push(`/dash/fseList`);
                          }}
                        >
                          View Team
                        </p>
                        <p
                          className="myteam_drop_p1"
                          onClick={() => {
                            localStorage.setItem(
                              "asm_id",
                              asmDummyData[index].asm_id
                            );
                            setTimeout(() => {
                              console.log("time pass");
                            }, 100);
                            window.scrollTo(0, 0);
                            history.push(`/dash/asmProfile`);
                          }}
                        >
                          View Profile
                        </p>
                        {/* <p
                          className="myteam_drop_p1"
                          style={{
                            backgroundColor: "rgba(255, 0, 0,0.2)",
                            color: "black",
                          }}
                        >
                          Terminate
                        </p>
                        <p className="myteam_drop_p1">Call</p> */}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="dashtl_card_row2">
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Checkins</p>
                  <p className="dashtl_p4">{checkin_thismonth}</p>
                  {checkin_today !== "0" ? (
                    <p className="dashtl_p5">+{checkin_today}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Leads</p>
                  <p className="dashtl_p4">{leads_thismonth}</p>
                  {leads_today !== "0" ? (
                    <p className="dashtl_p5">+{leads_today}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Approved</p>
                  <p className="dashtl_p4">{approved_thismonth}</p>
                  {approved_today !== "0" ? (
                    <p className="dashtl_p5">+{approved_today}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="my-card-footer">
                <div>
                  Onboarded by: {onboarded_by} &nbsp; &nbsp; {onboarded_on}{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                </div>
                <div>
                  {inactivated_by !== "" ? (
                    <>
                      <span style={{ color: "red" }}>Inactivated by :</span>{" "}
                      {inactivated_by}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
            </div>
          )
        )}
        {/* <div className="dashtl_card">
          <div className="dashtl_card_row1">
            <div className="dashtl1_card_col1">
              <div className="dashtl_small_col">
                <img
                  src={window.location.origin + "/images/png/fse.png"}
                  alt="profile"
                  className="profile_tl"
                />
                {1 !== "NA" ? (
                  <p className="tllist_active">active</p>
                ) : (
                  <p
                    className="tllist_active"
                    style={{ color: "red", background: "#ffd9d9" }}
                  >
                    inactive
                  </p>
                )}
              </div>

              <div className="dashtl1_card_col2 ">
                <div className="dashtl_card_row2" style={{ margin: "10px" }}>
                  {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )}
                  <p className="dashtl_p6">anjana</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Team Size:</p>
                  <p className="dashtl_card_p2">1234</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">DOJ:</p>
                  <p className="dashtl_card_p2">12/06/2021</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Lodcation:</p>
                  <p className="dashtl_card_p2">Bangalore</p>
                </div>
              </div>
            </div>
            <div
              className="dashtl_card_col3"

            >
              <img
                src={window.location.origin + "/images/svg/view_details.svg"}
                alt="profile"
                className="svg_myteam"
              />

              {

                active.length < 0 ? ( 
                  <div className="dashtl_dropdown">
                    <div className="tl_dropdown">
                      <p className="myteam_drop_p1" onClick={handlePop}>
                        View details
                      </p>
                      <p className="myteam_drop_p1">Download report</p>
                      <p className="myteam_drop_p1">Mark as Inactive</p>
                      <p className="myteam_drop_p1">Call</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
            </div>
          </div>

          <div className="dashtl_card_row2">
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Checkins</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  0000
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Leads</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Approved</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="dashtl_card">
          <div className="dashtl_card_row1">
            <div className="dashtl1_card_col1">
              <div className="dashtl_small_col">
                <img
                  src={window.location.origin + "/images/png/fse.png"}
                  alt="profile"
                  className="profile_tl"
                />
                {1 !== "NA" ? (
                  <p className="tllist_active">active</p>
                ) : (
                  <p
                    className="tllist_active"
                    style={{ color: "red", background: "#ffd9d9" }}
                  >
                    inactive
                  </p>
                )}
              </div>

              <div className="dashtl1_card_col2 ">
                <div className="dashtl_card_row2" style={{ margin: "10px" }}>
                  {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )}
                  <p className="dashtl_p6">anjana</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Fse Id:</p>
                  <p className="dashtl_card_p2">1234</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">DOJ:</p>
                  <p className="dashtl_card_p2">12/06/2021</p>
                </div>
              </div>
            </div>
            <div
              className="dashtl_card_col3"
              
            >
              <img
                src={window.location.origin + "/images/svg/view_details.svg"}
                alt="profile"
                className="svg_myteam"
              />

              {
                
                active.length < 0 ? ( 
                  <div className="dashtl_dropdown">
                    <div className="tl_dropdown">
                      <p className="myteam_drop_p1" onClick={handlePop}>
                        View details
                      </p>
                      <p className="myteam_drop_p1">Download report</p>
                      <p className="myteam_drop_p1">Mark as Inactive</p>
                      <p className="myteam_drop_p1">Call</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
            </div>
          </div>

          <div className="dashtl_card_row2">
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Leads</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  0000
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">QC Approved</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Stars</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
          </div>
        </div>
 */}

        {/* ))} */}

        {/* // )} */}
      </div>
    </div>
  );
}

export default AsmList;
