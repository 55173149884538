import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { startResetMyStats } from "../action/myStats";

import "./SideBar.css";

function SideBar(props) {
  const [option, setOption] = useState([true,false, true, true, true, true, true]);

  const handleClick = (e) => {
    console.log(e, "target hit");
    if (e==="0"){
      props.dispatch(startResetMyStats())
    }
    let swap = [];
    for (let i = 0; i < option.length; i++) {
      if (i == e) {
        swap.push(false);
      } else {
        swap.push(true);
      }
    }
    // swap[e]=!swap[e]
    setOption(swap);
  };
  return (
    <div className="pa_sidebar">
      <div className="side_row1">
        <img
          src={window.location.origin + "/images/profile image.svg"}
          alt="profile"
          className="side_profile"
        />
        <div className="side_box">
          <h3 className="side_h3">Abhijit M</h3>
          <p className="side_p1">Project Associate</p>
        </div>
      </div>
      <div className="side_row2">
        <Link to="/dash">
          <div
            onClick={() => handleClick("0")}
            className={option[0] ? "side_tabs" : "side_tabs1"}
          >
            Home
          </div>
        </Link>
        <Link to="/home">
          <div
            onClick={() => handleClick("1")}
            className={option[1] ? "side_tabs" : "side_tabs1"}
          >
            Project Details
          </div>
        </Link>
        <Link to="/home/update-doc">
          <div
            onClick={() => handleClick("2")}
            className={option[2] ? "side_tabs" : "side_tabs1"}
          >
            Update Training document
          </div>
        </Link>
        <Link to="/home/setup-training">
          <div
            onClick={() => handleClick("3")}
            className={option[3] ? "side_tabs" : "side_tabs1"}
          >
            Setup training questions
          </div>
        </Link>
        <Link to="/home/launch-proj">
          <div
            onClick={() => handleClick("4")}
            className={option[4] ? "side_tabs" : "side_tabs1"}
          >
            Launch project
          </div>
        </Link>
        <Link to="/home/runpayroll">
          <div
            onClick={() => handleClick("5")}
            className={option[5] ? "side_tabs" : "side_tabs1"}
          >
            Run payroll
          </div>
        </Link>
        <Link to="/home/update-report">
          <div
            onClick={() => handleClick("6")}
            className={option[6] ? "side_tabs" : "side_tabs1"}
          >
            Update report
          </div>
        </Link>
      </div>
    </div>
  );
}

export default connect()(SideBar);
