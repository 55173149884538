import {combineReducers,createStore,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import loggedinReducer from "../reducer/loggedin"
import projectListReducer from '../reducer/projectList'
import myStatsReducer from "../reducer/myStats"
import myTeamReducer from '../reducer/myTeam'
import asmCheckinReducer from '../reducer/asmCheckin'
import leadRegisterReducer from '../reducer/leadRegister'
import totalAttendanceReducer from '../reducer/totalAttendance'
import daywiseOverviewReducer from '../reducer/daywiseOverview'
import asmListReducer from '../reducer/asmList'
import asmDetailReducer from '../reducer/asmDetail'
import fseDetailReducer from '../reducer/fseDetail'
import accessPinReducer from '../reducer/accessPin'
import loginErrorFSReducer from '../reducer/loginErrorFS'

const configureStore=()=>{
    const store=createStore(combineReducers({
      loggedin:loggedinReducer,
      projectList: projectListReducer,
      myStats: myStatsReducer,
      myTeam: myTeamReducer,
      asmCheckin: asmCheckinReducer,
      leadRegister: leadRegisterReducer,
      totalAttendance: totalAttendanceReducer,
      daywiseOverview: daywiseOverviewReducer,
      asmList: asmListReducer,
      asmDetail: asmDetailReducer,
      fseDetail: fseDetailReducer,
      accessPin: accessPinReducer,
      loginErrorFS:loginErrorFSReducer
      
    }),applyMiddleware(thunk))
    return store
}

export default configureStore