import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Login.css";
import MyGoogleLogin from "./GoogleLogin/index"

import { connect } from "react-redux";

import { startLogin } from "./action/loggedin";
import {startAccess} from "./action/loginErrorFS";
function Login(props) {
  
  const history=useHistory();
  const [loginError, setLoginError] = useState("");
  const loginFunc = (r) => {
    console.log(r, "r-login");
    localStorage.setItem("hr_token", r.accessToken);
    localStorage.setItem("hr_email", r.profileObj.email);
    // setLogin(true);
    props.dispatch(startLogin(true));
    props.dispatch(startAccess(true));
    // history.push(`/dash/asmList`);
    window.location.href="/dash/asmList";
    console.log(props.loggedin,"loggedin state check after reload")
  };
  const logoutFunc = () => {
    // setLogin(false);
    props.dispatch(startLogin(false));
    props.dispatch(startAccess(false));
    localStorage.removeItem("hr_token");
    localStorage.removeItem("hr_email");
    history.push("/");
  };
  return (
    <div className="p_login">
      <div className="log_col1">
        <img
          src={window.location.origin + "/images/CM_LOGIN.svg"}
          alt="login"
          className="login_bg"
        />
      </div>
      <div className="log_col2">
        <h1 className="log_name">fieldon</h1>
        <h2 className="log_sub">City Manager Dashboard</h2>
        {/* <Link to="/dash"> */}
          {/* <div className="login_button" onClick={loginFunc}> */}
            {/* <img
              src={window.location.origin + "/images/LOGIN BUTTON.svg"}
              alt="login"
              className="login_but"
            />

            <p className="log_p1">login with google</p> */}
            
            <MyGoogleLogin loginFunc={loginFunc} logoutFunc={logoutFunc} setLoginError={setLoginError} />

          {/* </div> */}
        {/* </Link> */}
        <div style={{color:"red",marginTop:"10px"}}>{props.loginErrorFS}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginErrorFS: state.loginErrorFS,
    loggedin:state.loggedin
  };
};

export default connect(mapStateToProps)(Login);

// style={{
//     width: "300px",
//     height: "70px",
//     background: `url('${process.env.PUBLIC_URL}/images/LOGIN BUTTON.svg')`,
//   }}
